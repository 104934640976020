var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card-code",
    {
      attrs: { title: "Close On Click" },
      scopedSlots: _vm._u([
        {
          key: "code",
          fn: function() {
            return [_vm._v(" " + _vm._s(_vm.codeCloseOnClick) + " ")]
          },
          proxy: true
        }
      ])
    },
    [
      _c("b-card-text", [
        _c("code", [_vm._v("closeOnClick")]),
        _c("span", [_vm._v(" is set to ")]),
        _c("code", [_vm._v("false")]),
        _c("span", [
          _vm._v(
            " , the context menu will not automatically close when clicked on. You can toggle this feature on and off in this demo by using the checkbox below."
          )
        ])
      ]),
      _c(
        "b-button",
        {
          directives: [
            {
              name: "ripple",
              rawName: "v-ripple.400",
              value: "rgba(113, 102, 240, 0.15)",
              expression: "'rgba(113, 102, 240, 0.15)'",
              modifiers: { "400": true }
            }
          ],
          attrs: { variant: "outline-primary" },
          on: {
            contextmenu: function($event) {
              $event.preventDefault()
              return _vm.$refs.menuProp.open($event)
            }
          }
        },
        [_vm._v(" Right click on me - Do not close ")]
      ),
      _c(
        "vue-context",
        { ref: "menuProp", attrs: { "close-on-click": false } },
        _vm._l(_vm.menuData, function(data) {
          return _c(
            "li",
            { key: data.icon },
            [
              _c(
                "b-link",
                {
                  staticClass: "d-flex align-items-center",
                  on: {
                    click: function($event) {
                      return _vm.optionClicked(data.text, data.icon)
                    }
                  }
                },
                [
                  _c("feather-icon", {
                    attrs: { icon: data.icon, size: "16" }
                  }),
                  _c("span", { staticClass: "ml-75" }, [
                    _vm._v(_vm._s(data.text))
                  ])
                ],
                1
              )
            ],
            1
          )
        }),
        0
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }